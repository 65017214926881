import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ProductIndex from '../components/productIndex';

const ProductIndexPage = ({ data }) => (
  <Layout
    Content={props => (
      <ProductIndex edges={data.productLine.edges} {...props} />
    )}
  />
);

export const query = graphql`
  query($locale: String!) {
    productLine: allProductlineCsv(
      filter: { fields: { locale: { eq: $locale } } }
    ) {
      edges {
        node {
          Product_Line
          Product_Category
          Locale
          Keywords
          fields {
            locale
            slug
          }
        }
      }
    }
  }
`;

export default ProductIndexPage;
