import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { compose, withProps, withState } from 'recompose';
import { escapeRegExp, toSlug } from '../utils';

const enhance = compose(
  withProps(props => {
    const { edges } = props;

    const categories =
      edges &&
      edges.reduce((cats, item) => {
        const itemCat = item.node.Product_Category;

        if (!cats.includes(itemCat)) {
          cats.push(itemCat);
        }
        return cats;
      }, []);

    return { categories, edges, ...props };
  }),
  withState('activeCategory', 'updateActiveCategory', null),
  withState('inputValue', 'updateValue', ''),
  withProps(props => {
    const { activeCategory, edges } = props;

    const filteredEdges = activeCategory
      ? edges.filter(edge => edge.node.Product_Category === activeCategory)
      : edges;

    return {
      activeCategory,
      filteredEdges,
      ...props,
    };
  })
);

const filteredData = (data, input) => {
  const results = input
    ? data.filter(item => {
        const { node } = item;
        console.log(node);
        const itemString = `${node.Product_Line} ${node.Product_Category} ${node.Keywords}`;
        return itemString.search(new RegExp(input, 'i')) !== -1;
      })
    : data;

  return results;
};

const LIST_ITEM_HEIGHT = '3.5rem';

const Container = styled.div`
  margin: 1rem 0;
`;

const Cards = styled.div`
  flex-grow: 1;
  max-width: 33.333%;
  padding-left: 0.5rem;
`;

const CardContainer = styled.div`
  flex-basis: 100%;
  flex-shrink: 1;
  padding: 0.25rem;
  min-height: ${LIST_ITEM_HEIGHT};
  & > a {
    display: block;
    min-height: ${LIST_ITEM_HEIGHT};
    width: 100%;
  }
`;

const ProductLineCard = styled.div`
  background-color: #71c1e3;
  border: solid 1px #2d8db6;
  border-radius: 0.25rem;
  cursor: pointer;
  min-height: ${LIST_ITEM_HEIGHT};
  position: relative;
  text-align: center;
  width: 100%;
`;

const CardTitle = styled.h3`
  color: #ffffff;
  font-family: 'Muli';
  font-size: 1rem;
  font-weight: 400;
  left: 50%;
  margin: 0;
  padding: 0 1.5rem;
  position: absolute;
  text-align: left;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const SearchForm = styled.form`
  padding: 0 0.5rem;
  position: relative;
  margin-bottom: 0.5rem;
  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const SearchInput = styled.input`
  appearance: none;
  border-radius: 0.5rem;
  border: solid 1px #c4c4c4;
  color: #111111;
  display: block;
  font-family: 'Muli';
  font-size: 0.75rem;
  font-weight: 600;
  height: 2.5rem;
  padding: 0 0.5rem;
  width: 100%;
  @media (min-width: 767px) {
    font-size: 1rem;
    font-weight: 400;
    height: 3rem;
    padding: 0 1rem;
  }
  &:focus {
    outline: none;
  }
`;

const Icon = styled.div`
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translate(0, -50%);
  svg {
    fill: #ababab;
  }
`;

const Contents = styled.div`
  display: flex;
  padding: 0 0.25rem;
`;

const Sidebar = styled.div`
  flex-basis: 33.333%;
  min-width: 33.333%;
  padding: 0.25rem;
`;

const CategoryButton = styled.button`
  appearance: none;
  background-color: ${({ active }) => (active ? '#2D8DB6' : '#f2f2f2')};
  border-radius: 0.25rem;
  border: solid 1px ${({ active }) => (active ? '#2D8DB6' : '#d5d5d5')};

  border-radius: 0.25rem;
  cursor: pointer;
  line-height: 2.5rem;
  display: block;
  margin-bottom: 0.5rem;
  position: relative;
  min-height: ${LIST_ITEM_HEIGHT};
  width: 100%;
  & ${CardTitle} {
    color: ${({ active }) => (active ? '#ffffff' : '#3e424d')};
  }
  &:focus {
    outline: none;
  }
`;

const ContentHeading = styled.h2`
  color: #484848;
  font-size: 1rem;
  font-weight: 500;
`;

const ProductIndex = enhance(
  ({
    activeCategory,
    updateActiveCategory,
    categories,
    inputValue,
    updateValue,
    edges,
  }) => {
    const filteredEdges = activeCategory
      ? edges.filter(edge => edge.node.Product_Category === activeCategory)
      : edges;

    return <Container>
        <SearchForm>
          <SearchInput type="text" placeholder="Search by product name or category" onChange={e => updateValue(e.target.value)} />
          <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </svg>
          </Icon>
        </SearchForm>
        <Contents>
          <Sidebar>
            <ContentHeading>Product Categories</ContentHeading>
            <CategoryButton active={activeCategory === null} onClick={() => updateActiveCategory(null)}>
              <CardTitle>All</CardTitle>
            </CategoryButton>
            {categories && categories
                .sort((a, b) =>
                  a.localeCompare(b, 'fr', { ignorePunctuation: true })
                )
                .map(category => (
                  <CategoryButton
                    active={category === activeCategory}
                    onClick={() =>
                      activeCategory === category
                        ? updateActiveCategory(null)
                        : updateActiveCategory(category)
                    }
                    key={category}
                  >
                    <CardTitle>{category}</CardTitle>
                  </CategoryButton>
                ))}
          </Sidebar>
          <Cards>
            <ContentHeading>Product Lines</ContentHeading>
            {filteredEdges && filteredData(filteredEdges, escapeRegExp(inputValue)).map(
                item => {
                  const name = item.node.Product_Line;
                  const locale = item.node.fields.locale;
                  const slug = `${locale}/${toSlug(name)}`;
                  return (
                    <CardContainer key={slug}>
                      <Link to={slug}>
                        <ProductLineCard>
                          <CardTitle>{name}</CardTitle>
                        </ProductLineCard>
                      </Link>
                    </CardContainer>
                  );
                }
              )}
          </Cards>
        </Contents>
      </Container>;
  }
);

ProductIndex.propTypes = {
  edges: PropTypes.array,
  inputValue: PropTypes.string,
  updateValue: PropTypes.func,
};

export default ProductIndex;
